import { downloadBlob } from '@/utilities/blob';
import { Temporal } from '@js-temporal/polyfill';
import {
  TZ_EUROPE_LONDON,
  formatPlainDate,
  isToday,
  todayLocal,
} from '@packfleet/datetime';
import { orderBy } from 'lodash';
import * as Papa from 'papaparse';
import {
  ClaimStatus,
  ExternalShipmentFragment,
  OrderDirection,
  PackStatus,
  ShipmentFragment,
  ShipmentImportSource,
  ShipmentOrderField,
  ShipmentServiceTimeConstraints,
  ShipmentServiceType,
} from '../generated/graphql';
import { formatMoney } from './money';
import { formatTimeMinutes, getNextHalfHourMinutes, parseTime } from './time';

export const DEFAULT_SERVICE_TYPE = ShipmentServiceType.NextDay;

export const PACK_STATUS_ORDERING: Record<PackStatus, number> = {
  [PackStatus.Scheduled]: 0,
  [PackStatus.OutForCollection]: 1,
  [PackStatus.Collected]: 2,
  [PackStatus.InDepot]: 3,
  [PackStatus.ExternalCarrierCollected]: 4,
  [PackStatus.ExternalCarrierInTransit]: 5,
  [PackStatus.OutForDelivery]: 6,
  [PackStatus.AvailableForRecipientPickup]: 7,
  [PackStatus.Delivered]: 8,
  [PackStatus.FailedDelivery]: 9,
  [PackStatus.OnHold]: 10,
  [PackStatus.ToReturnToSender]: 11,
  [PackStatus.ReturnedToSender]: 12,
  [PackStatus.Undeliverable]: 13,
};

export type CSVShipmentRowKey = keyof typeof CSV_SHIPMENT_SCHEMA;
export type CSVShipmentRow = Record<CSVShipmentRowKey, string>;
export const CSV_SHIPMENT_SCHEMA = {
  name: 'Name',
  company_name: 'Company name',
  phone_number: 'Phone number',
  email: 'Email',
  notes: 'Notes',
  address_line_1: 'Address line 1',
  address_line_2: 'Address line 2',
  address_line_3: 'Address line 3',
  city: 'City',
  postCode: 'Postcode',
  external_reference: 'Order reference',
  earliest_delivery_date: 'Earliest delivery date',
  earliest_delivery_time: 'Earliest delivery time',
  latest_delivery_time: 'Latest delivery time',
  num_packs: 'Number of packs',
  weight_g: 'Weight (grams)',
  brand_name: 'Brand',
} as const;

export function formatServiceType(st: ShipmentServiceType) {
  switch (st) {
    case ShipmentServiceType.NextDay:
      return 'Next day';
    case ShipmentServiceType.TwoDay:
      return 'Two day';
    case ShipmentServiceType.SameDay:
      return 'Same day by 10pm';
  }
}

export function getPackStatusColor(status: PackStatus) {
  return {
    [PackStatus.Scheduled]: 'text-info',
    [PackStatus.OutForCollection]: '',
    [PackStatus.Collected]: 'text-brandDark',
    [PackStatus.InDepot]: 'text-neutral',
    [PackStatus.ExternalCarrierCollected]: 'text-neutral',
    [PackStatus.ExternalCarrierInTransit]: 'text-neutral',
    [PackStatus.OutForDelivery]: 'text-warning',
    [PackStatus.AvailableForRecipientPickup]: 'text-warning',
    [PackStatus.Delivered]: 'text-success',
    [PackStatus.FailedDelivery]: 'text-danger',
    [PackStatus.OnHold]: 'text-neutral',
    [PackStatus.ToReturnToSender]: 'text-danger',
    [PackStatus.ReturnedToSender]: 'text-info',
    [PackStatus.Undeliverable]: 'text-danger',
  }[status];
}

export function getPackStatusBgColor(status: PackStatus) {
  return {
    [PackStatus.Scheduled]: 'bg-info',
    [PackStatus.OutForCollection]: '',
    [PackStatus.Collected]: 'bg-brandDark',
    [PackStatus.InDepot]: 'bg-neutral',
    [PackStatus.ExternalCarrierCollected]: 'bg-neutral',
    [PackStatus.ExternalCarrierInTransit]: 'bg-neutral',
    [PackStatus.OutForDelivery]: 'bg-warning',
    [PackStatus.AvailableForRecipientPickup]: 'bg-warning',
    [PackStatus.Delivered]: 'bg-success',
    [PackStatus.FailedDelivery]: 'bg-danger',
    [PackStatus.OnHold]: 'bg-neutral',
    [PackStatus.ToReturnToSender]: 'bg-danger',
    [PackStatus.ReturnedToSender]: 'bg-info',
    [PackStatus.Undeliverable]: 'bg-danger',
  }[status];
}

export function formatPackStatusForMerchants(status: PackStatus): string {
  return {
    [PackStatus.Scheduled]: 'Booked for collection',
    [PackStatus.OutForCollection]: 'Out for collection',
    [PackStatus.Collected]: 'Collected',
    [PackStatus.InDepot]: 'In depot',
    [PackStatus.ExternalCarrierCollected]: 'Collected by delivery partner',
    [PackStatus.ExternalCarrierInTransit]: 'In transit with delivery partner',
    [PackStatus.OutForDelivery]: 'Out for delivery',
    [PackStatus.AvailableForRecipientPickup]: 'Available for recipient pickup',
    [PackStatus.Delivered]: 'Delivered',
    [PackStatus.FailedDelivery]: 'Delivery failed',
    [PackStatus.OnHold]: 'On hold',
    [PackStatus.ToReturnToSender]: 'Returning to sender',
    [PackStatus.ReturnedToSender]: 'Returned to sender',
    [PackStatus.Undeliverable]: 'Undeliverable',
  }[status];
}

export function formatClaimStatus(status: ClaimStatus): string {
  return {
    [ClaimStatus.AwaitingInformation]: 'Claim awaiting information',
    [ClaimStatus.InReview]: 'Claim in review',
    [ClaimStatus.InExternalReview]: 'Claim in external review',
    [ClaimStatus.Approved]: 'Claim approved',
    [ClaimStatus.Rejected]: 'Claim rejected',
  }[status];
}

export function getClaimStatusColor(status: ClaimStatus) {
  return {
    [ClaimStatus.AwaitingInformation]: 'text-warning',
    [ClaimStatus.InReview]: 'text-warning',
    [ClaimStatus.InExternalReview]: 'text-warning',
    [ClaimStatus.Approved]: 'text-success',
    [ClaimStatus.Rejected]: 'text-danger',
  }[status];
}

export function formatPackStatusForTracking(status: PackStatus): string {
  return {
    [PackStatus.Scheduled]: 'Booked for collection',
    [PackStatus.OutForCollection]: 'Out for collection',
    [PackStatus.Collected]: 'Collected from merchant',
    [PackStatus.InDepot]: 'In our depot',
    [PackStatus.ExternalCarrierCollected]: 'Collected by delivery partner',
    [PackStatus.ExternalCarrierInTransit]: 'In transit with delivery partner',
    [PackStatus.OutForDelivery]: 'Out for delivery',
    [PackStatus.AvailableForRecipientPickup]: 'Available for pickup',
    [PackStatus.Delivered]: 'Delivered',
    [PackStatus.FailedDelivery]: 'Delivery failed',
    [PackStatus.OnHold]: 'On hold',
    [PackStatus.ToReturnToSender]: 'Returning to sender',
    [PackStatus.ReturnedToSender]: 'Returned to sender',
    [PackStatus.Undeliverable]: 'Undeliverable',
  }[status];
}

export function isPackStatusFinal(status: PackStatus) {
  return [
    PackStatus.Delivered,
    PackStatus.FailedDelivery,
    PackStatus.Undeliverable,
    PackStatus.ReturnedToSender,
    PackStatus.ToReturnToSender,
  ].includes(status);
}

export function getNextLogicalPackStatus(status: PackStatus) {
  return {
    [PackStatus.Scheduled]: PackStatus.Collected,
    [PackStatus.OutForCollection]: PackStatus.Collected,
    [PackStatus.Collected]: PackStatus.InDepot,
    [PackStatus.InDepot]: PackStatus.OutForDelivery,
    [PackStatus.ExternalCarrierCollected]: PackStatus.ExternalCarrierInTransit,
    [PackStatus.ExternalCarrierInTransit]: PackStatus.OutForDelivery,
    [PackStatus.OutForDelivery]: PackStatus.Delivered,
    [PackStatus.AvailableForRecipientPickup]: PackStatus.Delivered,
    [PackStatus.Delivered]: PackStatus.FailedDelivery,
    [PackStatus.FailedDelivery]: PackStatus.InDepot,
    [PackStatus.OnHold]: PackStatus.ToReturnToSender,
    [PackStatus.ToReturnToSender]: PackStatus.ReturnedToSender,
    [PackStatus.ReturnedToSender]: PackStatus.InDepot,
    [PackStatus.Undeliverable]: PackStatus.Undeliverable,
  }[status];
}

export function applyTimeConstraints<_T extends Temporal.PlainDate>({
  startTime,
  endTime,
  timeConstraints,
  dateTz,
}: {
  startTime?: string | null;
  endTime?: string | null;
  timeConstraints: ShipmentServiceTimeConstraints;
  dateTz?: {
    date: Temporal.PlainDate;
    timezone: string;
  };
}) {
  let earliestStartTimeMinutes = 0;
  if (dateTz && isToday(dateTz.date, dateTz.timezone)) {
    earliestStartTimeMinutes = getNextHalfHourMinutes();
  }

  const startTimeMinutes = Math.max(
    earliestStartTimeMinutes,
    parseTime(startTime).totalMinutes,
  );
  const endTimeMinutes = parseTime(endTime).totalMinutes;

  const latestStartTime = parseTime(
    timeConstraints.latestStartTime,
  ).totalMinutes;
  const earliestEndTime = parseTime(
    timeConstraints.earliestEndTime,
  ).totalMinutes;
  const latestEndTime = parseTime(timeConstraints.latestEndTime).totalMinutes;
  const earliestStartTime = parseTime(
    timeConstraints.earliestStartTime,
  ).totalMinutes;
  const minTimeSlotMinutes = timeConstraints.minimumTimeRangeMins;

  const timeSlotEndTime =
    Math.min(startTimeMinutes, latestStartTime) + minTimeSlotMinutes;

  const earliestValidEndTime = Math.max(
    timeSlotEndTime,
    earliestEndTime,
    Math.min(endTimeMinutes, latestEndTime),
  );

  const validEndTime = Math.min(earliestValidEndTime, latestEndTime);

  const timeSlotStartTime = validEndTime - minTimeSlotMinutes;

  const latestValidStartTime = Math.min(
    startTimeMinutes,
    timeSlotStartTime,
    latestStartTime,
  );

  const validStartTime = Math.max(earliestStartTime, latestValidStartTime);

  return {
    startTime: formatTimeMinutes(validStartTime),
    endTime: formatTimeMinutes(validEndTime),
    startTimeMinutes: validStartTime,
    endTimeMinutes: validEndTime,
  };
}

type CSVRow = {
  tracking_phrase: string;
  external_id?: string | null;
  external_reference?: string | null;
  collection_date?: string;
  collection_earliest_time?: string | null;
  collection_latest_time?: string | null;
  collection_location_name: string;
  carrier: string;
  service_type: string;
  source?: string | null;
  brand_id?: string | null;
  brand_name?: string | null;
  number_of_packs: number;
  status: string;
  delivery_date: string;
  delivery_earliest_time?: string | null;
  delivery_latest_time?: string | null;
  delivery_notes?: string | null;
  recipient_name?: string | null;
  recipient_company_name?: string | null;
  recipient_email?: string | null;
  recipient_phone?: string | null;
  recipient_access_instructions?: string | null;
  recipient_address_line1: string;
  recipient_address_line2?: string | null;
  recipient_address_line3?: string | null;
  recipient_address_post_code: string;
  recipient_address_city?: string;
  shipment_id: string;
  collection_id?: string | null;
  collection_location_id: string;
  created_at: string;
  updated_at: string;
  billing_amount: string | null;
  labels_printed: string;
  pack_number?: number;
  weight_g?: number | null;
};

export function exportShipments(
  shipments: Array<ShipmentFragment | ExternalShipmentFragment>,
  multipleRowsPerShipment: boolean,
) {
  let csvRows: CSVRow[] = shipments.map<CSVRow>((s) => {
    if (s.__typename === 'Shipment') {
      return {
        tracking_phrase: s.trackingNumber,
        external_id: s.externalId,
        external_reference: s.externalReference,
        collection_date: s.collection?.date,
        collection_earliest_time: s.collection?.startTime,
        collection_latest_time: s.collection?.endTime,
        collection_location_name:
          s.collectionLocation.name || s.collectionLocation.address.line1,
        carrier: 'packfleet',
        service_type: s.serviceType,
        source: s.source,
        brand_id: s.brand?.id,
        brand_name: s.brand?.name,
        number_of_packs: s.packs.length,
        status: s.status,
        delivery_date: s.delivery.date,
        delivery_earliest_time: s.delivery.startTime,
        delivery_latest_time: s.delivery.endTime,
        delivery_notes: s.delivery.notes,
        recipient_name: s.delivery.location.name,
        recipient_company_name: s.delivery.location.companyName,
        recipient_email: s.delivery.location.email,
        recipient_phone: s.delivery.location.phone,
        recipient_access_instructions: s.delivery.location.accessNotes,
        recipient_address_line1: s.delivery.location.address.line1,
        recipient_address_line2: s.delivery.location.address.line2,
        recipient_address_line3: s.delivery.location.address.line3,
        recipient_address_post_code: s.delivery.location.address.postCode,
        recipient_address_city: s.delivery.location.address.city,
        shipment_id: s.id,
        collection_id: s.collection?.id,
        collection_location_id: s.collectionLocation.id,
        created_at: s.createdAt,
        updated_at: s.updatedAt,
        billing_amount:
          s.billingAmount && s.billingCurrency
            ? formatMoney(s.billingAmount, s.billingCurrency)
            : null,
        labels_printed: s.labelsPrinted ? 'Yes' : 'No',
        weight_g: s.packs.reduce<number | undefined>(
          (acc, p) => (p.weightG ? (acc ?? 0) + p.weightG : acc),
          undefined,
        ),
      };
    } else if (s.__typename === 'ExternalShipment') {
      return {
        tracking_phrase: s.trackingNumber,
        external_id: s.externalId,
        external_reference: s.externalReference,
        collection_date: s.collection?.date,
        collection_earliest_time: s.collection?.startTime,
        collection_latest_time: s.collection?.endTime,
        collection_location_name:
          (s.collectionLocation?.name || s.collectionLocation?.address.line1) ??
          'Unknown',
        carrier: s.carrierCode,
        service_type: s.serviceCode,
        source: s.source,
        brand_id: s.brand?.id,
        brand_name: s.brand?.name,
        number_of_packs: s.packs.length,
        status: s.status,
        delivery_date: s.deliveryDate ?? '',
        delivery_earliest_time: s.startTime,
        delivery_latest_time: s.endTime,
        delivery_notes: s.notes,
        recipient_name: s.name,
        recipient_company_name: s.companyName,
        recipient_email: s.email,
        recipient_phone: s.phone,
        recipient_address_line1: s.address?.line1 ?? '',
        recipient_address_line2: s.address?.line2,
        recipient_address_line3: s.address?.line3,
        recipient_address_post_code: s.address?.postCode ?? '',
        recipient_address_city: s.address?.city ?? '',
        shipment_id: s.id,
        collection_id: s.collection?.id,
        collection_location_id: s.collectionLocation?.id ?? '',
        created_at: s.createdAt,
        updated_at: s.updatedAt,
        billing_amount:
          s.billingAmount && s.billingCurrency
            ? formatMoney(s.billingAmount, s.billingCurrency)
            : null,
        labels_printed: s.labelsPrinted ? 'Yes' : 'No',
        weight_g: s.packs.reduce<number | undefined>(
          (acc, p) => (p.weightG ? (acc ?? 0) + p.weightG : acc),
          undefined,
        ),
      };
    } else {
      throw new Error('Unknown shipment type');
    }
  });

  if (multipleRowsPerShipment) {
    csvRows = csvRows.reduce<CSVRow[]>((acc, row) => {
      for (let n = 1; n <= row.number_of_packs; n++) {
        acc.push({ ...row, pack_number: n });
      }

      return acc;
    }, []);
  }

  const csvFile = Papa.unparse(csvRows, {
    header: true,
  });

  downloadBlob({
    data: csvFile,
    type: 'text/csv',
    filename: '',
  });
}

export function isStatusFinal(status: PackStatus): boolean {
  const finalStatuses: PackStatus[] = [
    PackStatus.Delivered,
    PackStatus.ReturnedToSender,
    PackStatus.ToReturnToSender,
  ];
  return finalStatuses.includes(status);
}

export function sanitizePostcode(postCode: string) {
  return postCode.replace(/[^a-zA-Z0-9\s]*/gi, '').trim();
}

export function isShipment(
  shipment: ShipmentFragment | ExternalShipmentFragment,
): shipment is ShipmentFragment {
  return shipment.__typename === 'Shipment';
}

export function isExternalShipment(
  shipment: ShipmentFragment | ExternalShipmentFragment,
): shipment is ExternalShipmentFragment {
  return shipment.__typename === 'ExternalShipment';
}

// We treat Packfleet-delivered shipments and shipments delivered by external couriers differently in the DB.
// We don't want to end up relying on endpoints/queries that treat them as the same thing, so treat
// this as a purely display concern and sort the combined list on the FE.
export function sortMixedShipments(
  mixedShipments: (ShipmentFragment | ExternalShipmentFragment)[],
  orderByField?: ShipmentOrderField,
  orderByDirection?: OrderDirection,
) {
  return orderBy(
    mixedShipments,
    [
      (s): string => {
        switch (orderByField) {
          case ShipmentOrderField.CollectionDate: {
            return (
              s.collection?.date ??
              formatPlainDate(todayLocal(TZ_EUROPE_LONDON))
            );
          }
          case ShipmentOrderField.CreatedAt: {
            return s.createdAt;
          }
          case ShipmentOrderField.ExternalReference: {
            return s.externalReference || '';
          }
          case ShipmentOrderField.DeliveryLocationName: {
            if (isShipment(s)) {
              return s.delivery.location.name || '';
            }
            return s.name || '';
          }
          case ShipmentOrderField.PostCode: {
            if (isShipment(s)) {
              return s.delivery.location.address.postCode;
            }
            return s.address?.postCode || '';
          }
          case undefined:
            return '';
        }
      },
      // Always do a secondary sort by external ID to ensure consistent ordering
      // and to match the CSV import order (if applicable)
      (s) => (s.source === ShipmentImportSource.CsvFile ? s.externalId : null),
    ],
    [orderByDirection === OrderDirection.Desc ? 'desc' : 'asc', 'asc'],
  );
}
